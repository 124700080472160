// import home from '../../api/home'
import http from '../../utils/http'

// initial state
const state = {
    projects: [],
    categories: [],
    advertisements: [],
    selectedCategoryID: 0,
}

// getters
const getters = {
    projectsQTY: state => {
        return state.projects.length;
    }
}

// actions
const actions = {
    getProjects({ commit }, { val, pageNumber }) {
        let t = this;
        commit('setSelectedCategoryID', val);
        let filter = {
            IsStart: { Value: 1 },
            Status: { Value: 2 },
            Sort: { Sort: -1 },

        };
        switch (val) {
            //预告
            case -1:
                {
                    filter.IsStart = { Value: 0 }
                    break;
                }
                //精选
            case -2:
                {
                    filter.IsRecommend = { Value: 1 }
                    break;
                }
            default:
                {
                    filter.Category = { Value: val }
                    break;
                }
        }
        filter.Pager = { PageNumber: pageNumber }
        http.post("/api/project/list", filter, (data) => {
            if (data.Success) {

                t.commit("commonMutations/setTotalPageCount", data.Data.PageCount);

                if (pageNumber == 1) {
                    commit('setProjects', data.Data.Results);
                } else if (pageNumber > data.Data.PageCount) {
                    commit('setAddProjects', data.Data.Results);
                }
                t.commit("commonMutations/setIsPageLoadBusy", false);
                console.log(data.Data.Results)
            }
        }, (e) => {
            console.log(e);
        });
    },
    getAllCategory: function({ commit }) {
        return new Promise((resolve, reject) => {
            http.get("/api/project/category", (data) => {
                if (data.Success) {
                    let categoriesNow = [];
                    //Value不能传0，避免一些其他组件相关状态判断失效
                    let dataSelected = { DisplayName: '精选', Value: -2 };
                    let dataNotice = { DisplayName: '预告', Value: -1 };

                    categoriesNow = categoriesNow.concat(dataSelected, data.Data, dataNotice);

                    commit('setCategory', categoriesNow);
                    resolve();
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getAdvertisement: function({ commit }) {
        return new Promise((resolve, reject) => {
            http.get("/api/advertisement/get", (data) => {
                if (data.Success) {
                    commit('setAdvertisement', data.Data);
                    resolve();
                }
            }, (e) => {
                console.log(e);
            });
        });
    }
}

// mutations
const mutations = {
    setProjects(state, projects) {
        state.projects = projects

    },
    setAddProjects(state, projects) {
        state.projects = state.projects.concat(projects)
    },
    setCategory(state, categories) {
        state.categories = categories
    },
    setAdvertisement(state, advertisements) {
        state.advertisements = advertisements

    },
    setSelectedCategoryID(state, id) {
        state.selectedCategoryID = id
    }
}

export default {
    namespaced: true,
    state, // 共同维护的一个状态，state里面可以是很多个全局状态
    getters, // 获取数据并渲染
    actions, // 数据的异步操作
    mutations // 处理数据的唯一途径，state的改变或赋值只能在这里
}