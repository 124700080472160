<template>
  <div class="bottom-container">
      <div class="tab">
        <div v-for="tab in tabs" :key="tab.idx" :class="{'tab-item':true,'active':tab.active}" v-on:click="tabClick(tab)">
          <div class="icon">
            <img :src="tab.active?tab.icon:tab.icon1">
          </div>
          <div class="title">{{tab.name}}</div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  created: function() {

  },
  data() {
    return {
     tabs:[
            {idx:1,name:this.$t('tabs.home'),path:'index',icon:require('@/assets/icon/home.png'),icon1:require('@/assets/icon/home1.png'),active:true,},
            {idx:2,name:this.$t('tabs.content'),path:'class',icon:require('@/assets/icon/function.png'),icon1:require('@/assets/icon/function1.png'),active:false},
            {idx:4,name:this.$t('tabs.me'),path:'personal',icon:require('@/assets/icon/my.png'),icon1:require('@/assets/icon/my1.png'),active:false}
          ]
    };
  },
  watch:{
    $route(to, from) {
      if(to.meta.tabIdx){
        this.activeTab(to.meta.tabIdx);
      }
    }
  },
  methods: {
    activeTab(idx){
      for(var i=0;i<this.tabs.length;i++){
        if(idx==this.tabs[i].idx){
          this.tabs[i].active=true;
        }
        else{
          this.tabs[i].active=false;
        }
      }
    },
    tabClick:function(tab){
      // this.activeTab(tab.idx);
      this.$emit('tabChanged',tab);
      this.$router.push({name:tab.path});
    }
  },
  beforeDestroy: function () {
                //卸载组件
  },
  mounted: function () {
    if(this.$route.meta&&this.$route.meta.tabIdx){
      this.activeTab(this.$route.meta.tabIdx);
    }
  },
  props: {
      btnText: {
        type: String,
        required: false
      },
  },
};
</script>
<style lang="css" scoped>
.bottom-container{
    position: fixed;
    bottom: -1px;
    height: 60px;
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
    box-sizing: border-box;
    z-index: 999;
}
  .tab{
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .tab .tab-item{
    display: flex;
        min-width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tab .tab-item .icon{
    width: 26px;
    height: 26px;
  }
  .tab .tab-item .icon img{
    width: 100%;
    height: 100%;
  }
  .tab .tab-item .title{
      font-size: 14px;
      font-weight: 500;
  }
  .tab .active{
      font-weight: 600;
      color:#ff3333;
  }
</style>
