import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import home from './modules/home'
import user from './modules/user'
import product from './modules/product'
import media from './modules/media'
import redenvelope from './modules/redenvelope'
//公用状态存储
import commonState from './modules/commonState';
import commonMutations from './modules/mutations';
import commonActions from './modules/actions';
// import createLogger from '../../../src/plugins/logger'
const debug = process.env.NODE_ENV !== 'production'
    // export default new Vuex.Store({
    //   modules: {
    //     commonMutations,
    //     commonState,
    //     commonActions,
    //     home,
    //     user,
    //     feiyan,
    //     product,
    //     media,
    //     redenvelope
    //   },
    //   strict: debug,
    //   plugins:[createPersistedState({
    //     key:'asstar',
    //     storage: window.localStorage,
    //     reducer(val) {
    //         return {
    //         // 只储存state中的user
    //         user: val.user
    //       }
    //    }
    //   })]
    // })
export const store = createStore({
    modules: {
        commonMutations,
        commonState,
        commonActions,
        home,
        user,
        product,
        media,
        redenvelope
    },
    strict: debug,
    plugins: [createPersistedState({
        key: 'iotmobile',
        storage: window.localStorage,
        reducer(val) {
            return {
                // 只储存state中的user
                user: val.user
            }
        }
    })]
})