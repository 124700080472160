/*
 * @Author: your name
 * @Date: 2022-01-04 10:19:10
 * @LastEditTime: 2022-11-12 14:03:57
 * @LastEditors: WangZhengJie wangzj@geovis.com.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \iotmobile\src\store\modules\user.js
 */
import http from '../../utils/http'
import userService from '../../services/UserService'
// initial state
const state = {
    isLogin: false,
    userName: '',
    userID: 0,
    token: '',
    photo: '',
    nickName: '',
    profile: '',
    channel: '0', //来自于哪个渠道
    share: '0', //哪个用户分享的,
    thridID:'',//第三方ID,比如微信的openid
}

// getters
const getters = {}

// actions
const actions = {
        login({ commit }, data) {
            userService.ExpressLogin(data).then(s => {
                if (s.Success) {
                    commit('login', s.Data);
                    data.success && data.success(s);
                } else {
                    data.error && data.error(s.Message)
                }
            }).catch(err => data.error && data.error(err));
        },
        setToken({commit},data){
            commit('setToken',data);
            // userService.GetUserProfile().then(s => {
            //     if (s.Success) {
            //         commit('login', s.Data);
            //         data.success && data.success(s);
            //     } else {
            //         data.error && data.error(s.Message)
            //     }
            // }).catch(err => data.error && data.error(err));
        },
        setLoginInfo({ commit }, data) {
            commit('login', data);
            data.success && data.success(data);
        },
        logout({ commit }) {
            commit('logout');
        },
        getUserInfo({ commit }) {
            http.get("/api/user/info/get", (data) => {
                if (data.Success) {
                    commit('userInfo', data.Data);
                }
            }, (e) => {
                console.log(e);
            });
        },
        trace({ commit }, data) {
            commit('trace', data);
        }
    }
    // mutations
const mutations = {
    setToken(state,data){
       state.token=data;
    },
    login(state, data) {
        state.isLogin = true;
        state.userID = data.User.ID;
        state.token = data.Token;
        state.userName = data.User.RealName;
        state.photo = data.User.Photo;
        state.profile = data.User.Profile;
        state.nickName = data.User.NickName;
        state.thridID=data.User.ThridID||'';
    },
    logout(state) {
        state.isLogin = false;
        state.token = null;
        state.userName = '未登录';
        state.userID = 0;
        state.photo = "";
        state.channel = '0';
        state.share = '0';
        state.profile = '';
        state.NickName = '未登录';
    },
    userInfo(state, data) {
        state.photo = data.Photo;
        state.nickName = data.NickName;
        state.profile = data.Profile;
    },
    trace(state, data) {
        state.channel = data.channel ? data.channel : state.channel;
        state.share = data.share ? data.share : state.share;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}