import md5 from '../utils/md5'
export default class hashCode{
    static skuHashCode(params) {
        var props = [];
        //1.拆解
        if (params instanceof Array) {
            props = params;
        }
        else {
            var propsArray = params.split('&');
            props=propsArray.map(function (item) {
                var propVal = item.split('=');
                return { id: propVal[0], value: propVal[1] };
            });
        }
        //2.开始排序
        var len = props.length, temp;
        for (var i = 0; i < len - 1; i++) {
            var min = props[i];
            for (var j = i + 1; j < len; j++) {
                if (props[j].id < min.id) {
                    temp = min;
                    min = props[j];
                    props[j] = temp;
                }
            }
            props[i] = min;
        }
        //3.重新拼接
        var newScode=props.map(function (item) { return item.id + '=' + item.value; }).join('&');
        //4.返回hashcode
        return md5(newScode);
    }
}