<template>
  <div class="footer">
    <slot></slot>
  </div>
</template>
<script>
export default {
  created: function() {},
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  methods: {
    back: function() {
      this.$emit('back');
    },
    toDo:function(){
      this.$emit('do');
    }
  },
  beforeDestroy: function () {
                //卸载组件
  },
  props: {
      btnText: {
        type: String,
        required: false
      },
  },
};
</script>
<style lang="css" scoped>
.footer {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 222;
}
</style>
