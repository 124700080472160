// import home from '../../api/home'
import http from '../../utils/http'
// initial state
const state = {
    redEnvelopeRecords: [],
}

// getters
const getters = {

}

// actions
const actions = {
    openRedEnvelope({ commit }, { shareCode }) {
        return new Promise((resolve, reject) => {
            http.post("/Api/User/OpenRedEnvelope?shareCode=" + shareCode, {}, (data) => {
                if (data.Success) {
                    resolve(data.Data);
                }
            }, (e) => {
                console.log(e);
            });
        })
    },
    checkRedEnvelopeStatus({ commit }) {
        return new Promise((resolve, reject) => {
            http.post("/Api/User/CheckRedEnvelopeStatus/", {}, (data) => {
                if (data.Success) {
                    resolve(data.Data);
                }
            }, (e) => {
                console.log(e);
            });
        })
    },
    getRedEnvelopeRecord: function({ commit }) {
        http.get("/Api/User/GetRedEnvelopeRecord", (data) => {
            if (data.Success) {
                commit('setRedEnvelopeRecord', data.Data.Results);
            }
        }, (e) => {
            console.log(e);
        });
    }
}

// mutations
const mutations = {
    setRedEnvelopeRecord(state, records) {
        state.redEnvelopeRecords = records
    },
}

export default {
    namespaced: true,
    state, // 共同维护的一个状态，state里面可以是很多个全局状态
    getters, // 获取数据并渲染
    actions, // 数据的异步操作
    mutations // 处理数据的唯一途径，state的改变或赋值只能在这里
}