import http from '../../utils/http'

// initial state
const state = {
        mediaList: [],
        selectedTabID: 0,
    }
    // getters
const getters = {
        mediaQTY: state => {
            return state.mediaList.length;
        }
    }
    // actions
const actions = {
    getMediaList({ commit }, { categoryID, pageNumber }) {
        let t = this;
        let filter = {};
        return new Promise((resolve, reject) => {
            commit('setSelectedTabID', categoryID)

            if (categoryID == 0) {
                filter = { Sort: { Value: null, Sort: -2 } }
            } else if (categoryID == 1) {
                filter = { FavoriteQuantity: { Value: null, Sort: -2 } }
            }
            // let filter={
            //   FavoriteQuantity:{Value:null,Sort:-2},
            //  // CreateTime: { Sort: 1 },
            // }
            filter.Pager = { PageNumber: pageNumber, PageSize: 30 };
            http.post("/api/news/list", filter, (data) => {
                if (data.Success) {
                    //console.log('11111'+data.Data.Results);
                    if (pageNumber == 1) {
                        commit('setMediaList', data.Data.Results);
                    } else if (pageNumber > data.Data.PageCount) {
                        commit('setAddCategory', data.Data.Results);
                    }
                    // t.commit("commonMutations/setIsPageLoadBusy", false);
                    resolve();
                    //console.log(data.Data.Results)
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
}

// mutations
const mutations = {

    setSelectedTabID(state, index) {
        state.selectedTabID = index
    },
    setMediaList(state, mediaList) {
        state.mediaList = mediaList
    }
}

export default {
    namespaced: true,
    state, // 共同维护的一个状态，state里面可以是很多个全局状态
    getters, // 获取数据并渲染
    actions, // 数据的异步操作
    mutations // 处理数据的唯一途径，state的改变或赋值只能在这里
}