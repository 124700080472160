// import commonState from './commonState';

const mutations = {
  // add(commonState){
  //     commonState.count++
  // },
  // reduce(commonState){
  //     commonState.count--
  // },
  setIsPageLoadShow: function ($store, val) {
    this.state.commonState.isPageLoadShow = val
  },
  setIsPageLoadBusy: function ($store, val) {
    this.state.commonState.isPageLoadBusy = val
  },
  setIsReachLastPage: function ($store, val) {
    this.state.commonState.isReachLastPage = val
  },
  setTotalPageCount: function ($store, val) {
    this.state.commonState.totalPageCount = val
  },
  setCurPageNumber: function ($store, val) {
    this.state.commonState.curPageNumber = val
  }

}


export default {
  namespaced: true,
  mutations// 数据的异步操作
}

