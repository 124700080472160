import http from "../utils/http"
export default class ApiService {
    Get(url, res) {
        return new Promise((resolve, reject) => {
            http.get(url, (res) => {
                resolve(res);
            }, (error) => {
                reject(error);
                console.log(error);
            });
        });
    }
    Post(url, data) {
        return new Promise((resolve, reject) => {
            http.post(url, data, (res) => {
                resolve(res);
            }, (error) => {
                reject(error);
                console.log(error);
            });
        });
    }
    Delete(url, res) {
        return new Promise((resolve, reject) => {
            http.delete(url, (res) => {
                resolve(res);
            }, (error) => {
                reject(error);
                console.log(error);
            });
        });
    }
    Upload(url, formData) {
        return new Promise((resolve, reject) => {
            http.upload(url, formData, (res) => {
                resolve(res);
            }, (error) => {
                reject(error);
                console.log(error);
            });
        });
    }
    Delete(url, res) {
        return new Promise((resolve, reject) => {
            http.delete(url, (res) => {
                resolve(res);
            }, (error) => {
                reject(error);
                console.log(error);
            });
        });
    }
}