import { createI18n } from 'vue-i18n'
// 引入各个语言配置文件
import zh from './zh';
import en from './en';
const messages = {
    zh,
    en
}
const i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: 'zh',
  messages
})
export default i18n