/*
 * @Date: 2021-12-22 10:45:01
 * @LastEditors: OBKoro1
 * @LastEditTime: 2022-04-01 13:59:22
 * @FilePath: \iotmobile\src\utils\http.js
 */
import config from '../config'
export default {
    getToken: function() {
        let authString = localStorage.getItem('iotmobile');
        let authData = JSON.parse(authString);
        return authData ? authData.user.token : "";
    },
    post: function(url, data, success, error) {
        // alert(config.apiHost + url);
        fetch(config.apiHost + url, {
                method: 'POST',
                headers: {
                    'Access-Token': this.getToken()
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(data => {
                success && success(data)
            }).catch(e => {
                error && error(e);
            });
    },
    get: function(url, success, error) {
        // alert(config.apiHost + url);
        fetch(config.apiHost + url, {
                method: 'GET',
                headers: {
                    'Access-Token': this.getToken()
                },
                mode: 'cors',
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                success && success(data)
            }).catch(e => {
                error && error(e);
            });
    },
    delete: function(url, success, error) {
        fetch(config.apiHost + url, {
                method: 'DELETE',
                headers: {
                    'Access-Token': this.getToken()
                },
                mode: 'cors',
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                success && success(data)
            }).catch(e => {
                error && error(e);
            });
    },
    upload: function(url,formData, success, error) {
        fetch(config.apiHost + url, {
                method: 'POST',
                headers: {
                    'Access-Token': this.getToken(),
                },
                body:formData,
                mode: 'cors',
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                success && success(data)
            }).catch(e => {
                error && error(e);
            });
    }
}