// import home from '../../api/home'
import http from '../../utils/http'
import hashCode from '../../utils/hashCode'

// initial state
const state = {
    products: [],
    product: { Images: [], SPU: { Title: '' } },
    checkedSKU: { ID: 0, Quantity: 1, ImageUrl: '', Price: 0, VisitorID: '' },
    checkedPropertys: [],
    advertisements: [],
    categories: [{ Name: '推荐', ParentID: 0 }, { Name: '新品', ParentID: 0 }], //销售类目
    selectedCategoryID: 0,
    assembleList: [],
}

// getters
const getters = {
    productQTY: state => {
        return state.products.length;
    }
}

// actions
const actions = {
    getAllCategory: function({ commit }) {
        return new Promise((resolve, reject) => {
            http.get("/api/product/salecategory", (data) => {
                if (data.Success) {
                    commit('setCategory', data.Data);
                    commit('setSelectedCategoryID', data.Data[0].ID);
                    resolve(data.Data[0].ID);
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getProducts({ commit }, { categoryID, pageNumber }) {
        let t = this;
        return new Promise((resolve, reject) => {
            commit('setSelectedCategoryID', categoryID);
            let filter = {
                SaleCategoryIDs: { Value: ',' + categoryID + ',' },
                CreateTime: { Sort: 1 },
            }

            filter.Pager = { PageNumber: pageNumber, PageSize: 30 }
            http.post("/api/product/list", filter, (data) => {
                if (data.Success) {
                    t.commit("commonMutations/setTotalPageCount", data.Data.PageCount);

                    if (pageNumber == 1) {
                        commit('setProducts', data.Data.Results);
                    } else if (pageNumber > data.Data.PageCount) {
                        commit('setAddCategory', data.Data.Results);
                    }
                    t.commit("commonMutations/setIsPageLoadBusy", false);
                    resolve();
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getProductsRec({ commit }, { pageNumber }) {
        let t = this;
        return new Promise((resolve, reject) => {
            let filter = {
                CreateTime: { Sort: 1 },
            }

            filter.Pager = { PageNumber: pageNumber, PageSize: 30 }
            http.post("/api/product/recommendlist", filter, (data) => {
                if (data.Success) {
                    t.commit("commonMutations/setTotalPageCount", data.Data.PageCount);

                    if (pageNumber == 1) {
                        commit('setProducts', data.Data.Results);
                    } else if (pageNumber > data.Data.PageCount) {
                        commit('setAddCategory', data.Data.Results);
                    }
                    t.commit("commonMutations/setIsPageLoadBusy", false);
                    resolve();
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getProductsNew({ commit }, { pageNumber }) {
        let t = this;
        return new Promise((resolve, reject) => {
            let filter = {
                CreateTime: { Sort: 1 },
            }

            filter.Pager = { PageNumber: pageNumber, PageSize: 30 }
            http.post("/api/product/newlist", filter, (data) => {
                if (data.Success) {
                    t.commit("commonMutations/setTotalPageCount", data.Data.PageCount);

                    if (pageNumber == 1) {
                        commit('setProducts', data.Data.Results);
                    } else if (pageNumber > data.Data.PageCount) {
                        commit('setAddCategory', data.Data.Results);
                    }
                    t.commit("commonMutations/setIsPageLoadBusy", false);
                    resolve();
                    console.log(data.Data.Results)
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getProduct({ commit }, { productID }) {
        return new Promise((resolve, reject) => {
            http.get("/api/product/" + productID, (data) => {
                if (data.Success) {
                    commit('setProduct', data.Data);
                    resolve(data.Data);
                }
            }, (e) => {
                console.log(e);
            });
        })
    },
    getAdvertisement: function({ commit }) {
        return new Promise((resolve, reject) => {
            http.get("/api/advertisement/get", (data) => {
                if (data.Success) {
                    commit('setAdvertisement', data.Data);
                    resolve();
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
    getAssembleList: function({ commit }) {
        return new Promise((resolve, reject) => {
            let filter = {
                Pager: {
                    PageNumber: 1,
                    PageSize: 10
                }
            };
            http.post("/api/product/grouplist", filter, (data) => {
                if (data.Success) {
                    commit('setAssembleList', data.Data.Results);
                    resolve();
                    console.log(data.Data)
                }
            }, (e) => {
                console.log(e);
            });
        });
    },
}

// mutations
const mutations = {
    setCategory(state, categories) {
        state.categories = state.categories.concat(categories)
        console.log(state.categories)
    },
    setAddCategory(state, categories) {
        state.categories = state.categories.concat(categories)
    },
    setSelectedCategoryID(state, id) {
        state.selectedCategoryID = id
    },
    setAdvertisement(state, advertisements) {
        state.advertisements = advertisements
    },
    setProducts(state, products) {
        state.products = products
    },
    setProduct(state, product) {
        state.product = product;
        state.checkedSKU.ImageUrl = state.product.SPU.ImageUrl;
        state.checkedSKU.Price = state.product.SPU.Price;
    },
    setAssembleList(state, assembleList) {
        state.assembleList = assembleList
    },
    incrementQTY(state) {
        state.checkedSKU.Quantity++;
    },
    decrementQTY(state) {
        if (state.checkedSKU.Quantity > 1) {
            state.checkedSKU.Quantity--;
        }
    },
    propertyValueChecked(state, propVal) {
        //处理必选区
        let checkedProperty = function(propID, propValID) {
            let isExists = false;
            for (let index = 0; index < state.checkedPropertys.length; index++) {
                const propVal = state.checkedPropertys[index];
                if (propVal.id == propID) {
                    propVal.value = propValID;
                    isExists = true;
                }
            }
            if (!isExists) {
                state.checkedPropertys.push({ id: propID, value: propValID });
            }
            // if(rmIndex==-1){
            //   if(isAdd) state.checkedPropertys.push({id:propID,value:propValID});
            // }
            // else {
            //   state.checkedPropertys.splice(rmIndex,1);
            // }
        }
        let matchSKU = function(hashCode) {
            for (let i = 0; i < state.product.SKUList.length; i++) {
                const sku = state.product.SKUList[i];
                if (sku.HashCode == hashCode) {
                    state.checkedSKU.ID = sku.ID;
                    state.checkedSKU.ImageUrl = sku.ImageUrl;
                    state.checkedSKU.Price = sku.Price;
                }
            }
        }

        for (let i = 0; i < state.product.Properties.length; i++) {
            let _prop = state.product.Properties[i];
            if (_prop.ID == propVal.PropertyID) {
                for (let j = 0; j < _prop.Values.length; j++) {
                    if (_prop.Values[j].ID == propVal.ID) {
                        _prop.Values[j].IsChecked = true;
                        checkedProperty(propVal.PropertyID, propVal.PropertyValueID);
                    } else {
                        _prop.Values[j].IsChecked = false;
                        //checkedProperty(propVal.PropertyID,propVal.PropertyValueID,false);
                    }
                }
            }
        }
        let hasCode = hashCode.skuHashCode(state.checkedPropertys);
        matchSKU(hasCode);
    },

}

export default {
    namespaced: true,
    state, // 共同维护的一个状态，state里面可以是很多个全局状态
    getters, // 获取数据并渲染
    actions, // 数据的异步操作
    mutations // 处理数据的唯一途径，state的改变或赋值只能在这里
}