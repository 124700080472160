/*
 * @Author: your name
 * @Date: 2021-10-25 09:13:46
 * @LastEditTime: 2023-04-27 09:36:08
 * @LastEditors: OBKoro1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \iotmobile\src\config.js
 */
// const apiHost = 'http://localhost:55138';
const apiHost = 'https://iotapi.asstar.com';
// const apiHost = 'http://192.168.31.125:55138';
// const apiHost = 'http://localhost:33567';
// const apiHost = 'http://192.168.31.237:33567';
// const apiHost = 'http://47.94.44.184:8081';
// const apiHost = 'http://192.168.160.73:33567';

// const  apiHost='http://192.168.0.4:33567';
//const  apiHost='https://testapi.asstar.com';
export default {
    apiHost
} 